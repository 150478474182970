import { useEffect, useState } from 'react';
import _ from 'lodash';

import useAuth from './useAuth';
import { DICTATION_PROVIDER } from 'src/constants';
import { useReduxStorage } from 'src/redux/store';

const SPEAKER_INDEPENDENT_MODEL_USERS = [
  'user_independent@voiceitt.com',
  //Intel test users
  'intel.ai.demo1@outlook.com',
  'intel.ai.demo2@outlook.com',
  'intel.ai.demo3@outlook.com',
  'intel.ai.demo4@outlook.com',
  'intel.ai.demo5@outlook.com',
  'intel.ai.demo6@outlook.com',
  'intel.ai.demo7@outlook.com',
  'intel.ai.demo8@outlook.com',
  'intel.ai.demo9@outlook.com',
  'intel.ai.demoa@outlook.com',
  'intelcorpdemos@gmail.com',
  'intelcorpdemos1@gmail.com',

  'tobiidynavox@voiceitt.com',
];

const useCasrInfo = () => {
  const { user } = useAuth();
  const [provider, setProvider] = useState(null);

  const modelsInfoStorage = useReduxStorage('models').state.data;
  const modelsInfo = modelsInfoStorage || [];

  const acousticModelTypes = [
    'acoustic_phoneme',
    'acoustic',
    'acoustic_icefall_pts7s',
  ];
  const accurateAcousticModel =
    _.find(modelsInfo, (m) => 'acoustic_phoneme' === m.type) ||
    _.find(modelsInfo, (m) => 'acoustic' === m.type);
  let isModelBuilt = modelsInfo.some((m) =>
    acousticModelTypes.includes(m.type),
  );
  if (SPEAKER_INDEPENDENT_MODEL_USERS.includes(user?.email)) {
    isModelBuilt = true;
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.providers_enabled.includes(DICTATION_PROVIDER)) {
      setProvider(DICTATION_PROVIDER);
    } else {
      setProvider(null);
    }
  }, [user, modelsInfo]);

  const isFetched = modelsInfoStorage !== undefined;

  const languageModel = _.find(
    modelsInfo,
    (m) => 'language_phoneme' === m.type || 'language' === m.type,
  );

  return {
    provider,
    isModelBuilt,
    accurateAcousticModel: isFetched
      ? accurateAcousticModel || null
      : undefined,
    isPersonalAvailable: isFetched
      ? (accurateAcousticModel && languageModel) || false
      : undefined,
  };
};

export default useCasrInfo;
