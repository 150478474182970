import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@mui/material';
import { FONT_FAMILY } from 'src/theme/typography';

const SCROLLBAR_STYLE = {
  scrollbarColor: '#E8E8E8 transparent',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    width: 8,
    height: 8,
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 4,
    backgroundColor: '#E8E8E8',
    border: '0px solid transparent',
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        backgroundColor: colors.common.white,
        ...SCROLLBAR_STYLE,
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.driver-popover': {
        maxWidth: '400px !important',
      },
      '.driver-popover-title': {
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `22px !important`,
      },
      '.driver-popover-description': {
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `18px !important`,
      },
      '.driver-popover-prev-btn': {
        display: 'none !important',
      },
      '.driver-popover-close-btn': {},
      '.driver-popover-arrow': {
        display: 'none !important',
      },
      '.driver-popover-next-btn': {
        fontFamily: `${FONT_FAMILY} !important`,
        fontSize: `16px !important`,
        fontWeight: `500 !important`,
        padding: '4px 18px !important',
        backgroundColor: '#2F84D3 !important',
        color: 'white !important',
        textShadow: 'unset !important',
        border: 'unset !important',
        borderRadius: '8px !important',
      },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
