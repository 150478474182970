import { useReduxStorage } from 'src/redux/store';
import _ from 'lodash';

const useSubscription = () => {
  const subscriptionInfoStorage = useReduxStorage('subscription_info');
  const subscriptionData = subscriptionInfoStorage.state.data;

  const subscriptionInfo = subscriptionData?.details || {};
  const preferredTechPartnerName = subscriptionData?.preferred_tech_partner;
  const techPartners = subscriptionData?.tech_partners || [];

  const preferredTechPartner = _.find(techPartners, {
    seller: preferredTechPartnerName,
  });
  const subscriptionTechPartner = _.find(techPartners, {
    seller: subscriptionInfo.seller,
  });

  const hasPreferredTechPartner = !!preferredTechPartner;
  const hasChosenSeller =
    subscriptionInfo &&
    !_.isEmpty(subscriptionInfo) &&
    subscriptionInfo?.seller !== 'demo' &&
    !!subscriptionTechPartner;

  let techPartner;
  if (hasPreferredTechPartner) {
    techPartner = preferredTechPartner;
  } else if (hasChosenSeller) {
    techPartner = subscriptionTechPartner;
  }

  return {
    subscriptionInfo,
    isSubscriptionLocked: subscriptionData?.is_locked || false,
    isPaymentEnabled: (subscriptionData?.tech_partners || []).length !== 0,
    techPartners,
    preferredTechPartnerName,
    techPartner,
    reload: subscriptionInfoStorage.loadForced,
    isLoaded: subscriptionInfoStorage.state.isLoaded,
  };
};

export default useSubscription;
