import _ from 'lodash';
import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import palette from './palette';

const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

const baseOptions = {
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiTableCell: {
      root: {
        padding: 10,
      },
    },
    MuiFormHelperText: {
      root: {
        backgroundColor: '#FFF4F2',
        color: '#d32f2f',
        marginTop: '1px',
      },
      contained: {
        marginLeft: 'unset',
        marginRight: 'unset',
        padding: '0px 14px',
      },
    },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          t0: 'p',
          t1: 'p',
          t2: 'p',
          t3: 'p',
          t3_light: 'p',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #73777F',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #2F84D3',
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#E9F2FA',
          },
          '&[aria-selected="true"].Mui-focused': {
            backgroundColor: '#E9F2FA',
          },
          '&.Mui-focused': {
            backgroundColor: '#F5F5F5',
          },
        },
        listbox: {
          padding: 0,
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        tabIndex: null, // Remove tabindex from all ButtonBase components
      },
    },

    MuiButton: {
      styleOverrides: {
        contained: ({ theme, maincolor }) => {
          const mainColorResolved =
            theme.palette[maincolor] ||
            (isColor(maincolor) && maincolor) ||
            theme.palette.brandBlue;
          return {
            fontSize: 16,
            fontWeight: 700,
            borderRadius: 6,
            backgroundColor: mainColorResolved,

            '&:disabled': {
              backgroundColor: '#97C2E9',
              color: mainColorResolved,
            },
            '&:hover': {
              backgroundColor: mainColorResolved,
            },
          };
        },
        outlined: ({ theme }) => ({
          fontSize: 16,
          fontWeight: 700,
          borderRadius: 6,
          border: `3px solid ${theme.palette.brandBlue}`,
          color: theme.palette.brandBlue,
          '&:hover': {
            border: `3px solid ${theme.palette.brandBlue}`,
          },
        }),
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#73777F',
          },
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    palette: {
      mode: 'light',
      brandBlue: '#2F84D3',
      brandBlueDisabled: '#97C2E9',
      brandDarkBlue: '#245284',
      brandOrange: '#EF7B43',
      brandRed: '#E5504F',
      panelBlue: '#FAFBFF',
      lightBlue: '#E9F2FA',
      text: {
        primary: '#353C45',
        alternative: '#FFFFFF',
        disabled: '#73777F',
      },
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
      },
    },
    shadows: softShadows,
  },
];

export const createTheme = (config = {}) => {
  const themeOptions = themesOptions.find(
    (theme) => theme.name === config.theme,
  );

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  const theme = responsiveFontSizes(
    createMuiTheme(_.merge({}, baseOptions, themeOptions)),
  );

  return theme;
};
