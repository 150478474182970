/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useReducer,
  useRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { io } from 'socket.io-client';
import _ from 'lodash';
import queue from 'queue';
import { Buffer } from 'buffer';

import useAuth from 'src/hooks/useAuth';
import useSettings, { SettingsKeys } from 'src/hooks/useSettings';
import { apiConfig, env } from 'src/config';
import {
  DICTATION_PROVIDER,
  APP_BUILD,
  SETTINGS_DISCRETE_ENABLED_PATH,
} from 'src/constants';
import usePrevious from 'src/hooks/usePrevious';
import { clientApi } from 'src/api/clientApi';
import useCasrInfo from 'src/hooks/useCasrInfo';
import logger from 'src/lib/logger';
import ProcessingHandler from 'src/lib/processing_handler';
import { getDeviceInfo } from 'src/utils/platform';
import { useReduxStorage } from 'src/redux/store';
import useAppInactivity from 'src/hooks/useAppInactivity';
import { createDeferred } from 'src/utils/deferred_promise';

export const RECOGNITION_SAMPLE_TYPE = {
  int16: 'int16',
  int32: 'int32',
  float32: 'float32',
  float64: 'float64',
};

const initialState = {
  socket: null,
  isConnected: false,
  isConnecting: false,
  provider: null,
  modelInfo: null,
  modelLoadStatus: null,
  isReady: false,
  recognition: null,
  isRecognitionProcessing: false,
  error: null,
  initProgress: null,
};

const ACTION = {
  SET_CONNECTED: 'SET_CONNECTED',
  SET_PROVIDER: 'SET_PROVIDER',
  SET_MODEL_INFO: 'SET_MODEL_INFO',
  SET_MODEL_LOAD_STATUS: 'SET_MODEL_LOAD_STATUS',
  CONNECTION_READY: 'CONNECTION_READY',
  SET_RECOGNITION: 'SET_RECOGNITION',
  SET_RECOGNITION_PROCESSING: 'SET_RECOGNITION_PROCESSING',
  SET_ERROR: 'SET_ERROR',
  RESET_RECOGNITION: 'RESET_RECOGNITION',
};

const handlers = {
  [ACTION.SET_CONNECTED]: (state, action) => {
    const { isConnected } = action.payload;
    return {
      ...state,
      isConnected,
      provider: null,
      modelInfo: null,
      modelLoadStatus: null,
      isReady: false,
      recognition: null,
      isRecognitionProcessing: false,
      error: null,
    };
  },

  [ACTION.SET_PROVIDER]: (state, action) => {
    const { provider } = action.payload;

    return {
      ...state,
      provider,
      modelInfo: null,
      modelLoadStatus: null,
      isReady: false,
      recognition: null,
      isRecognitionProcessing: false,
      error: null,
    };
  },
  [ACTION.SET_MODEL_INFO]: (state, action) => ({
    ...state,
    modelInfo: action.payload.modelInfo,
  }),
  [ACTION.SET_MODEL_LOAD_STATUS]: (state, action) => ({
    ...state,
    modelLoadStatus: action.payload.status,
  }),
  [ACTION.CONNECTION_READY]: (state) => ({
    ...state,
    isReady: true,
  }),
  [ACTION.SET_PARTIAL_RECOGNITION]: (state, action) => {
    const recognitions = [];
    const fullText = _.get(state, 'partial_recognition.full_text');
    if (!_.isEmpty(fullText)) {
      recognitions.push(fullText);
    }
    if (!_.isEmpty(action.payload.text)) {
      recognitions.push(action.payload.text);
    }
    return {
      ...state,
      partial_recognition: {
        full_text: recognitions.join(' '),
        text: action.payload.text,
        unstable_text: action.payload.unstable_text,
      },
    };
  },
  [ACTION.SET_RECOGNITION]: (state, action) => ({
    ...state,
    partial_recognition: null,
    recognition: { date: new Date(), text: action.payload.text },
  }),
  [ACTION.SET_RECOGNITION_PROCESSING]: (state, action) => ({
    ...state,
    isRecognitionProcessing: action.payload.isProcessing,
  }),
  [ACTION.SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error,
  }),
  [ACTION.RESET_RECOGNITION]: (state) => ({
    ...state,
    partial_recognition: null,
    recognition: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const useServerRecognition = () => {
  const { getToken, getRefreshToken, user } = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);

  const socketRef = useRef(null);
  const processingHandlerRef = useRef(null);
  const isStreamProcessingRef = useRef(null);
  const queueRef = useRef(queue({ concurrency: 1, autostart: true }));

  const onProcessingHandlerStateChange = (e) => {
    dispatch({
      type: ACTION.SET_RECOGNITION_PROCESSING,
      payload: { isProcessing: e.detail },
    });
  };

  const connect = async () => {
    const token = getToken();
    const refreshToken = getRefreshToken();
    if (!token || !refreshToken) {
      return false;
    }

    if (socketRef.current && socketRef.current.connected) {
      return true;
    }

    const onRecognition = (data) => {
      const text = data.text;
      console.log('recognition', text, new Date());
      dispatch({ type: ACTION.SET_RECOGNITION, payload: { text } });
      processingHandlerRef.current?.audioProcessed(data.request_id);
    };

    if (env.isDev) {
      window.recognition = onRecognition;
      window.recognitionText = (text) => onRecognition({ text });

      window.streamAudio = async (path = '/test_audio.wav', type = 'int16') => {
        const response = await fetch(path);
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();

        const arrayTypes = {
          int16: Int16Array,
          float32: Float32Array,
        };

        const arrayType = arrayTypes[type];

        const typedArray = new arrayType(arrayBuffer);
        const chunks = _.chunk(typedArray, 3200);
        for (const chunk of chunks) {
          const audioData = new arrayType(chunk);
          socketRef.current.emit('stream_audio_samples', audioData, type);
        }
        const audioData = new arrayType(new Array(16000));
        socketRef.current.emit('stream_audio_samples', audioData, type);

        //works with '/test_audio_2.wav' int16
        // const audioData = new Int16Array(arrayBuffer)
        // socketRef.current.emit('recognize_audio_samples', audioData, RECOGNITION_SAMPLE_TYPE.int16)
      };

      window.streamCompressedAudio = async (path = '/test_audio_3.wav') => {
        const response = await fetch(path);
        const blob = await response.blob();

        const splitBlob = (
          file,
          chunkSize /* chunkSize should be byte 1024*1 = 1KB */,
        ) => {
          let startPointer = 0;
          let endPointer = file.size;
          const cSize = Math.min(file.size, chunkSize);
          const chunks = [];
          while (startPointer < endPointer) {
            let newStartPointer = startPointer + cSize;
            chunks.push(file.slice(startPointer, newStartPointer));
            startPointer = newStartPointer;
          }
          return chunks;
        };

        const blobChunks = splitBlob(blob, 1024);

        for (const chunk of blobChunks) {
          socketRef.current.emit('stream_compressed_audio', chunk, blob.type);
        }
        socketRef.current.emit(
          'stream_compressed_audio',
          new Blob([Buffer.from(new Array(160000))]),
          { type: blob.type },
        );
      };
    }

    processingHandlerRef.current = new ProcessingHandler();
    processingHandlerRef.current.addEventListener(
      'change',
      onProcessingHandlerStateChange,
    );

    let socketLocal = socketRef.current;
    if (!socketLocal) {
      console.log('[Connect] Create');

      const options = {
        path: '/socket.io',
        autoConnect: true,
        withCredentials: true,
        transports: ['websocket', 'polling'],
        auth: (cb) => {
          cb({
            token: getToken(),
            refresh_token: getRefreshToken(),
            user_id: user.id,
          });
        },
      };
      try {
        const isDictationUser =
          user?.providers_enabled.includes(DICTATION_PROVIDER);
        const url = isDictationUser
          ? apiConfig.casrDictationSocketApi
          : apiConfig.casrSocketApi;
        const socket = io(url, options);
        socketLocal = socket;
        socketLocal.deferredPromise = createDeferred();
        socketRef.current = socketLocal;
      } catch (error) {
        console.log(
          `[Connect] io not a function but ${io}`,
          socketLocal,
          error,
        );
        return false;
      }

      socketLocal.io.on('open', () => {
        console.log('Socket connected', new Date());
        dispatch({
          type: ACTION.SET_CONNECTED,
          payload: { isConnected: true },
        });
        socketLocal.deferredPromise.resolve();
      });
      socketLocal.io.on('close', () => {
        console.log('Socket closed', new Date());
        dispatch({
          type: ACTION.SET_CONNECTED,
          payload: { isConnected: false },
        });
      });

      const logErrorDebounced = _.debounce(logger.error.bind(logger), 10000, {
        leading: true,
        trailing: true,
      });
      socketLocal.io.on('error', (data) => {
        const errorMsg = data?.type || JSON.stringify(data);
        console.error('Socket error data', errorMsg);
        if (data?.type !== 'TransportError') {
          logErrorDebounced(
            `[ServerRecognitionContext] Socket error: ${errorMsg}`,
          );
        }

        dispatch({ type: ACTION.SET_ERROR, payload: { error: data } });

        processingHandlerRef.current?.error(data.request_id, data);
      });
      socketLocal.io.on('reset_alb_cookies', async () => {
        console.log('reset_alb_cookies', new Date());
        resetAlbCookies();
        disconnect(true);
        connect();
      });

      socketLocal.on('connect_error', (data, e) => {
        console.error(`[ServerRecognitionContext] Socket connect error:`, data);

        dispatch({
          type: ACTION.SET_CONNECTED,
          payload: { isConnected: false },
        });
        dispatch({ type: ACTION.SET_ERROR, payload: { error: data } });

        socketLocal.deferredPromise.reject(data);
      });

      socketLocal.on('reset_alb_cookies', async () => {
        console.log('reset_alb_cookies', new Date());
        resetAlbCookies();
        disconnect(true);
        connect();
      });

      socketLocal.on('error', (data) => {
        const error = new Error(data?.message);
        error.code = data?.code;
        error.message_dev = data?.message_dev;
        dispatch({ type: ACTION.SET_ERROR, payload: { error: error } });

        logErrorDebounced(
          `[ServerRecognitionContext] Error code: ${error.code} msg: ${error.message}`,
        );
      });
      socketLocal.on('connection_ready', () => {
        console.log('connection_ready', new Date());
        dispatch({ type: ACTION.CONNECTION_READY });
      });
      socketLocal.on('request_received', (data) => {
        processingHandlerRef.current?.audioProcessing(data.request_id);
      });

      socketLocal.on('partial_recognition', (data) => {
        dispatch({ type: ACTION.SET_PARTIAL_RECOGNITION, payload: data });
      });
      socketLocal.on('recognition', onRecognition);
      socketLocal.on('wuw_verification', (data) => {
        processingHandlerRef.current?.audioProcessed(data.request_id);
      });
      socketLocal.on('model_missing', () => {
        console.log('model_missing', new Date());
        dispatch({
          type: ACTION.SET_MODEL_LOAD_STATUS,
          payload: { status: 'model_missing' },
        });
      });
      socketLocal.on('model_loading', () => {
        console.log('model_loading', new Date());
        dispatch({
          type: ACTION.SET_MODEL_LOAD_STATUS,
          payload: { status: 'model_loading' },
        });
      });
      socketLocal.on('model_loading_error', () => {
        console.log('model_loading_error', new Date());
        dispatch({
          type: ACTION.SET_MODEL_LOAD_STATUS,
          payload: { status: 'model_loading_error' },
        });
      });
      socketLocal.on('model_ready', (data) => {
        console.log('model_ready', new Date());
        dispatch({ type: ACTION.SET_MODEL_INFO, payload: { modelInfo: data } });
        dispatch({
          type: ACTION.SET_MODEL_LOAD_STATUS,
          payload: { status: 'model_ready' },
        });
      });
      await socketLocal.deferredPromise.promise;
    } else if (!socketLocal.connected && !socketLocal.active) {
      //attempt to connect when socket.io manages reconnection causes error and disconnected state. `active` helps to understand if we really need to reconnect.
      console.log('[Connect] Connect');
      socketLocal.deferredPromise = createDeferred();

      socketLocal.connect();
      await socketLocal.deferredPromise.promise;
    }

    return true;
  };

  const disconnect = (force = false) => {
    if (!force && (!socketRef.current || !state.isConnected)) {
      return false;
    }

    console.log('[Disconnect]');
    socketRef.current?.disconnect();

    processingHandlerRef.current?.removeEventListener(
      'change',
      onProcessingHandlerStateChange,
    );

    return true;
  };

  const selectProvider = (
    provider,
    metadata,
    use_streaming_model = false,
    domain_id = 'beta_phase',
    scenario_id = 'beta_phase',
    language = 'en',
  ) => {
    if (!socketRef.current || !state.isConnected) {
      return false;
    }

    if (isStreamProcessingRef.current) {
      stopStreamProcessing();
      disconnect(true);
      connect();
    }

    dispatch({
      type: ACTION.SET_PROVIDER,
      payload: { provider },
    });

    socketRef.current.emit('select_provider', {
      provider_id: provider,
      domain_id,
      scenario_id,
      language,
      metadata,
      app_build: APP_BUILD,
      use_streaming_model,
    });
    return true;
  };

  const recognize = (audio, type = RECOGNITION_SAMPLE_TYPE.int16, duration) => {
    if (
      !socketRef.current ||
      !state.isConnected ||
      state.modelLoadStatus !== 'model_ready'
    ) {
      return false;
    }
    if (!audio.length) {
      return false;
    }
    if (processingHandlerRef.current.isProcessing) {
      return false;
    }

    socketRef.current.emit('recognize_audio_samples', audio, type);
    console.log(`sending audio length ${audio.length} type ${type}`);

    processingHandlerRef.current?.audioSent(duration);

    return true;
  };

  const canStream =
    socketRef.current &&
    socketRef.current.connected &&
    state.isReady &&
    state.modelLoadStatus === 'model_ready';

  const stream = (audio, type = RECOGNITION_SAMPLE_TYPE.float32) => {
    if (!canStream) {
      return false;
    }
    if (!audio.length) {
      return false;
    }

    socketRef.current?.emit('stream_audio_samples', audio, type);

    return true;
  };

  const streamCompressedAudio = async (blob) => {
    if (!canStream) {
      return false;
    }
    if (!blob.size) {
      return false;
    }

    queueRef.current.push(async () => {
      const arrayBuffer = await blob.arrayBuffer();
      if (!isStreamProcessingRef.current) {
        return false;
      }
      socketRef.current?.emit(
        'stream_compressed_audio',
        arrayBuffer,
        blob.type,
      );
    });

    return true;
  };

  const setStreamingVadMinSilence = (duration = 0.3) => {
    if (!state || !state.isConnected) {
      return false;
    }

    return setOptions({ streaming_vad_min_silence_length: duration });
  };

  const startStreamProcessing = () => {
    if (!socketRef.current || !state.isConnected) {
      return false;
    }

    isStreamProcessingRef.current = true;
  };

  const stopStreamProcessing = () => {
    if (!socketRef.current || !state.isConnected) {
      return false;
    }

    queueRef.current.push(async () => {
      isStreamProcessingRef.current = false;
      socketRef.current.emit('stop_stream_processing');
    });
  };

  const setOptions = (options) => {
    if (!state || !state.isConnected) {
      return false;
    }

    socketRef.current?.emit('set_options', options);

    return true;
  };

  const setRecognitionMode = (mode) => {
    return setOptions({ recognition_mode: mode });
  };

  const setFilterProfanities = (value) => {
    return setOptions({ filter_profanities: value });
  };

  const resetAlbCookies = async () => {
    try {
      await clientApi.resetAlbCookies();
    } catch (error) {
      console.error(error);
    }
  };

  const resetRecognition = () => dispatch({ type: ACTION.RESET_RECOGNITION });

  const clearSocket = () => {
    socketRef.current = null;
  };

  const actions = {
    connect,
    disconnect,
    selectProvider,
    recognize,
    setRecognitionMode,
    resetAlbCookies,
    resetRecognition,
    stream,
    streamCompressedAudio,
    setStreamingVadMinSilence,
    setFilterProfanities,
    startStreamProcessing,
    stopStreamProcessing,
    clearSocket,
  };

  return {
    ...state,
    canStream,
    actions,
  };
};

export const ServerRecognitionContext = createContext({ state: initialState });

export const ServerRecognitionContextProvider = ({ children }) => {
  const ssr = useServerRecognition();

  const { isAuthenticated, user, fetchUserTasks, socketAuthFailed } = useAuth();
  const settings = useSettings();
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const modelsStorage = useReduxStorage('models');

  const casrInfo = useCasrInfo();
  const isAppInactive = useAppInactivity();

  const connectDeferredPromise = useRef();

  const connectMutex = async (fn) => {
    if (connectDeferredPromise.current) {
      await connectDeferredPromise.current.promise;
      return;
    }
    const deferredPromise = createDeferred();
    connectDeferredPromise.current = deferredPromise;
    try {
      await fn();
    } catch (error) {
    } finally {
      deferredPromise.resolve();
      connectDeferredPromise.current = null;
    }
  };

  const connect = async () => {
    await connectMutex(async () => {
      await ssr.actions.connect();
    });
  };

  const reConnect = async () => {
    await connectMutex(async () => {
      await socketAuthFailed();
      await ssr.actions.connect();
    });
  };

  useEffect(() => {
    const f = async () => {
      const isAlreadyStreamingError = ssr.error?.code === 1011;
      // const isTransportError = ssr.error?.type === 'TransportError';
      const isAuthError = 'authentication failed' === ssr.error?.message;
      if (isAuthError) {
        reConnect();
      } else if (isAlreadyStreamingError) {
        connect();
      } else if (
        isAuthenticated &&
        user &&
        !isAppInactive /* && !ssr.isConnected*/
      ) {
        connect();
      }
    };
    f();
  }, [
    isAuthenticated,
    user,
    isAppInactive,
    // Added ssr.isConnected for case when another streaming session is running
    // and when we try to start streaming, socket is disconnected and doesn't
    // try to reconnect because server aborted connection with code 41
    // ssr.isConnected,
    ssr.error,
  ]);

  const useStreamingModel = useMemo(
    () => user?.settings.app?.use_streaming_model,
    [user?.settings.app?.use_streaming_model],
  );

  const isDiscreteRecogitionOn =
    casrInfo.isPersonalAvailable &&
    settings.state[SettingsKeys.personalRecognitionOn] &&
    _.get(user?.settings, SETTINGS_DISCRETE_ENABLED_PATH);
  useEffect(() => {
    const updateProvider = async () => {
      if (!ssr.isConnected || !casrInfo.provider) {
        return;
      }
      const metadata = await getDeviceInfo();
      ssr.actions.selectProvider(
        casrInfo?.provider,
        JSON.stringify(metadata),
        !isDiscreteRecogitionOn && useStreamingModel,
        isDiscreteRecogitionOn ? 'user_phrasebook' : 'beta_phase',
      );
    };
    updateProvider().catch((err) =>
      logger.error(
        `[ServerRecognitionContext] updateProvider error ${err.message}`,
      ),
    );
  }, [
    casrInfo?.provider,
    ssr.isConnected,
    useStreamingModel,
    isDiscreteRecogitionOn,
  ]);

  //Disconnect on timeout
  useEffect(() => {
    if (isAppInactive) {
      ssr.actions.disconnect(true);
    }
  }, [isAppInactive]);

  //Disconnect on logout
  useEffect(() => {
    const isLoggedOut = !isAuthenticated && prevIsAuthenticated;
    if (isLoggedOut) {
      ssr.actions.resetAlbCookies();
      ssr.actions.disconnect(true);
      ssr.actions.clearSocket();
    }
  }, [isAuthenticated, prevIsAuthenticated]);

  //Update models and tasks for 'Recognition ready' screen and having latest models
  useEffect(() => {
    if (ssr.modelLoadStatus === 'model_ready') {
      fetchUserTasks();
      modelsStorage.loadForced();
    }
  }, [ssr.modelLoadStatus]);

  return (
    <ServerRecognitionContext.Provider
      value={{
        ...ssr,
        casrInfo,
        isInitializing: casrInfo.isModelBuilt && !ssr.canStream,
      }}
    >
      {children}
    </ServerRecognitionContext.Provider>
  );
};
