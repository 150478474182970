/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useReducer, useEffect } from 'react';

import useAuth from 'src/hooks/useAuth';
import usePrevious from 'src/hooks/usePrevious';

export const SettingsKeys = {
  zoomSeq: 'zoomSeq',
  recognitionMode: 'recognitionMode',
  isDebugOn: 'isDebugOn',
  defaultAudioSettings: 'defaultAudioSettings',
  audioNoiseSuppression: 'audioNoiseSuppression',
  audioAutoGainControl: 'audioAutoGainControl',
  audioEchoCancellation: 'audioEchoCancellation',
  audioAutoBufferLength: 'audioAutoBufferLength',
  audioNativeResampling: 'audioNativeResampling',
  localVAD: 'localVAD',
  finishedTrainingModalsWasShown: 'finishedTrainingModalsWasShown',
  notificationStatus: 'notificationStatus',
  recognitionsCount: 'recognitionsCount',
  sourceTracking: 'sourceTracking',
  loggerDefaultInfo: 'loggerDefaultInfo',
  handsFreeAutoRecord: 'handsFreeAutoRecord',
  handsFreeAutoListen: 'handsFreeAutoListen',
  handsFreeAutoPlay: 'handsFreeAutoPlay',
  handsFreeAutoSubmit: 'handsFreeAutoSubmit',
  ttsHighlight: 'ttsHighlight',
  voicePlaybackSpeed: 'voicePlaybackSpeed',
  textUpsideDown: 'textUpsideDown',
  lastOpenedTab: 'lastOpenedTab',
  controlDictate: 'controlDictate',
  openAiTermsAccepted: 'openAiTermsAccepted',
  openAiTermsSpeak: 'openAiTermsSpeak',
  openAiTermsDictate: 'openAiTermsDictate',
  zoomAccessToken: 'zoomAccessToken',
  zoomRefreshToken: 'zoomRefreshToken',
  localRecognition: 'localRecognition',
  externalSpeakerModeOn: 'externalSpeakerModeOn',
  externalSpeakerInputDevice: 'externalSpeakerInputDevice',
  acapellaToken: 'acapellaToken',
  personalRecognitionOn: 'personalRecognitionOn',
  constraintPickerPopupIntroShown: 'constraintPickerPopupIntroShown',
  constraintPickerPopupReminderShown: 'constraintPickerPopupReminderShown',
  constraintPickerPopupShown: 'constraintPickerPopupShown',
};

const SettingsDefaults = {
  [SettingsKeys.zoomSeq]: '0',
  [SettingsKeys.recognitionMode]: null,
  [SettingsKeys.isDebugOn]: 'false',
  [SettingsKeys.defaultAudioSettings]: 'true',
  [SettingsKeys.audioNoiseSuppression]: 'false',
  [SettingsKeys.audioAutoGainControl]: 'false',
  [SettingsKeys.audioEchoCancellation]: 'false',
  [SettingsKeys.audioAutoBufferLength]: 'true',
  [SettingsKeys.audioNativeResampling]: 'false',
  [SettingsKeys.localVAD]: 'false',
  [SettingsKeys.finishedTrainingModalsWasShown]: '{}',
  [SettingsKeys.notificationStatus]: '{}',
  [SettingsKeys.recognitionsCount]: '0',
  [SettingsKeys.sourceTracking]: null,
  [SettingsKeys.loggerDefaultInfo]: null,
  [SettingsKeys.handsFreeAutoRecord]: 'false',
  [SettingsKeys.handsFreeAutoListen]: 'false',
  [SettingsKeys.handsFreeAutoPlay]: 'false',
  [SettingsKeys.handsFreeAutoSubmit]: 'false',
  [SettingsKeys.ttsHighlight]: 'true',
  [SettingsKeys.voicePlaybackSpeed]: '0.75',
  [SettingsKeys.textUpsideDown]: 'false',
  [SettingsKeys.lastOpenedTab]: '/record',
  [SettingsKeys.controlDictate]: 'true',
  [SettingsKeys.openAiTermsSpeak]: 'false',
  [SettingsKeys.openAiTermsDictate]: 'false',
  [SettingsKeys.zoomAccessToken]: null,
  [SettingsKeys.zoomRefreshToken]: null,
  [SettingsKeys.localRecognition]: 'false',
  [SettingsKeys.externalSpeakerModeOn]: 'false',
  [SettingsKeys.externalSpeakerInputDevice]: null,
  [SettingsKeys.acapellaToken]: null,
  [SettingsKeys.personalRecognitionOn]: 'false',
  [SettingsKeys.constraintPickerPopupIntroShown]: 'false',
  [SettingsKeys.constraintPickerPopupReminderShown]: 'false',
  [SettingsKeys.constraintPickerPopupShown]: 'false',
};

const SettingsPersistentKeys = [SettingsKeys.lastOpenedTab];

const BoolConverter = {
  set: (v) => v.toString(),
  get: (v) => v === 'true',
};

const IntConverter = {
  set: (v) => v.toString(),
  get: (v) => parseInt(v),
};

const IntNotFixedConverter = {
  set: (v) => v.toString(),
  get: (v) => Number(v),
};

const JSONConverter = {
  set: JSON.stringify,
  get: JSON.parse,
};

const SettingsConverters = {
  [SettingsKeys.zoomSeq]: IntConverter,
  [SettingsKeys.isDebugOn]: BoolConverter,
  [SettingsKeys.defaultAudioSettings]: BoolConverter,
  [SettingsKeys.audioNoiseSuppression]: BoolConverter,
  [SettingsKeys.audioAutoGainControl]: BoolConverter,
  [SettingsKeys.audioEchoCancellation]: BoolConverter,
  [SettingsKeys.audioAutoBufferLength]: BoolConverter,
  [SettingsKeys.audioNativeResampling]: BoolConverter,
  [SettingsKeys.localVAD]: BoolConverter,
  [SettingsKeys.finishedTrainingModalsWasShown]: JSONConverter,
  [SettingsKeys.recognitionsCount]: IntConverter,
  [SettingsKeys.notificationStatus]: JSONConverter,
  [SettingsKeys.sourceTracking]: JSONConverter,
  [SettingsKeys.loggerDefaultInfo]: JSONConverter,
  [SettingsKeys.handsFreeAutoRecord]: BoolConverter,
  [SettingsKeys.handsFreeAutoListen]: BoolConverter,
  [SettingsKeys.handsFreeAutoPlay]: BoolConverter,
  [SettingsKeys.handsFreeAutoSubmit]: BoolConverter,
  [SettingsKeys.ttsHighlight]: BoolConverter,
  [SettingsKeys.voicePlaybackSpeed]: IntNotFixedConverter,
  [SettingsKeys.textUpsideDown]: BoolConverter,
  [SettingsKeys.controlDictate]: BoolConverter,
  [SettingsKeys.openAiTermsAccepted]: BoolConverter,
  [SettingsKeys.openAiTermsSpeak]: BoolConverter,
  [SettingsKeys.openAiTermsSpeak]: BoolConverter,
  [SettingsKeys.zoomAccessToken]: JSONConverter,
  [SettingsKeys.zoomRefreshToken]: JSONConverter,
  [SettingsKeys.localRecognition]: BoolConverter,
  [SettingsKeys.externalSpeakerModeOn]: BoolConverter,
  [SettingsKeys.externalSpeakerInputDevice]: JSONConverter,
  [SettingsKeys.acapellaToken]: JSONConverter,
  [SettingsKeys.personalRecognitionOn]: BoolConverter,
  [SettingsKeys.constraintPickerPopupIntroShown]: BoolConverter,
  [SettingsKeys.constraintPickerPopupReminderShown]: BoolConverter,
  [SettingsKeys.constraintPickerPopupShown]: BoolConverter,
};

export const Settings = (storage = localStorage) => {
  const getFullKey = (key) => `settings.${key}`;

  const clear = () => {
    Object.values(SettingsKeys).forEach((key) => {
      if (!SettingsPersistentKeys.includes(key)) {
        storage.removeItem(getFullKey(key));
      }
    });
  };

  const get = (k) => {
    if (!SettingsKeys.hasOwnProperty(k)) return;
    const converter = SettingsConverters[k];
    const defaultValue = SettingsDefaults[k];

    const key = getFullKey(k);
    let value = storage.getItem(key);
    if (value === null) {
      value = defaultValue;
    }
    if (converter) {
      return converter.get(value);
    } else {
      return value;
    }
  };

  const set = (k, v) => {
    if (!SettingsKeys.hasOwnProperty(k)) return;
    const converter = SettingsConverters[k];

    const key = getFullKey(k);
    if (converter) {
      storage.setItem(key, converter.set(v));
      return true;
    } else {
      storage.setItem(key, v);
      return true;
    }
  };

  const getAll = () =>
    Object.values(SettingsKeys).reduce((data, key) => {
      data[key] = get(key);
      return data;
    }, {});

  return { clear, get, set, getAll };
};

const handlers = {
  CLEAR: (state, action) => {
    const { settings } = action.payload;
    settings.clear();
    return settings.getAll();
  },

  SET: (state, action) => {
    const { settings, k, v } = action.payload;
    settings.set(k, v);

    return {
      ...state,
      [k]: v,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const nonDefaultSettingKeys = [
  SettingsKeys.audioNoiseSuppression,
  SettingsKeys.audioAutoGainControl,
  SettingsKeys.audioEchoCancellation,
  SettingsKeys.audioNativeResampling,
];
const audioSettingKeys = [
  SettingsKeys.defaultAudioSettings,
  SettingsKeys.audioAutoBufferLength,
  ...nonDefaultSettingKeys,
];

const SettingsReduced = () => {
  const settings = Settings();

  const initialState = {
    ...settings.getAll(),
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = {
    clear: () => dispatch({ type: 'CLEAR', payload: { settings } }),
    set: (k, v) => dispatch({ type: 'SET', payload: { settings, k, v } }),

    setAudioSetting: (k, v) => {
      if (!audioSettingKeys.includes(k)) {
        return;
      }
      if (v) {
        if (k === SettingsKeys.defaultAudioSettings) {
          nonDefaultSettingKeys.forEach((k) =>
            dispatch({ type: 'SET', payload: { settings, k, v: false } }),
          );
        } else if (nonDefaultSettingKeys.includes(k)) {
          dispatch({
            type: 'SET',
            payload: {
              settings,
              k: SettingsKeys.defaultAudioSettings,
              v: false,
            },
          });
        }
      }
      dispatch({ type: 'SET', payload: { settings, k, v } });
    },
    getAudioSettings: () => {
      const audioSettings = audioSettingKeys.reduce(
        (prev, k) => ({ ...prev, [k]: state[k] }),
        {},
      );

      if (state[SettingsKeys.defaultAudioSettings]) {
        nonDefaultSettingKeys.forEach((k) => (audioSettings[k] = false));
      } else if (nonDefaultSettingKeys.some((k) => state[k])) {
        nonDefaultSettingKeys[SettingsKeys.defaultAudioSettings] = false;
      }

      return audioSettings;
    },
  };

  return { state, actions };
};

export const SettingsContext = createContext({ state: SettingsDefaults });

export const SettingsContextProvider = ({ children }) => {
  const settings = SettingsReduced();

  const { isAuthenticated } = useAuth();
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  useEffect(() => {
    const isLoggedOut = !isAuthenticated && prevIsAuthenticated;
    if (isLoggedOut) {
      settings.actions.clear();
    }
  }, [isAuthenticated, prevIsAuthenticated]);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};
